import React from "react"
import "./StaticData.scss"
import { Container } from "react-bootstrap"
import { parseHTML } from "@lib/utils"

const StaticData = ({ title, description }) => {
  return (
    <Container>
      <div className="static-content-wrap">
        <h1 className="title">{title}</h1>
        {description && (
          <div className="description">{parseHTML(description)}</div>
        )}{" "}
      </div>
    </Container>
  )
}

export default StaticData
